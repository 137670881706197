import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '../utils/auth.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      navShow: false,
      title: '纵信快手项目管理'
    },
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/',
    
    component: () => import('../views/Home.vue'),
    children: [{
      path: '/',
      name: 'overview',
      meta: {
        navShow: true,
        title: '数据概览',
        url: '/'
      },
      component: () => import('../views/overview/overview.vue')
    },
    {
      path: '/ksAdvertiser',
      name: 'ksAdvertiser',
      meta: {
        navShow: true,
        title: '快手广告主',
        url: '/ksAdvertiser',
        parentMPName: '数据报表',
        parentPath: '/report'
      },
      component: () => import('../views/report/ksadvertiser.vue'),
    }, {
      path: '/dyAdvertiser',
      name: 'dyAdvertiser',
      meta: {
        navShow: true,
        title: '抖音广告主',
        url: '/dyAdvertiser',
        parentMPName: '数据报表',
        parentPath: '/report'
      },
      component: () => import('../views/report/dyadvertiser.vue'),
    },
    {
      path: '/transfer',
      name: 'transfer',
      meta: {
        navShow: true,
        title: '拼多多转链',
        url: '/transfer',
        parentMPName: '商品转链',
        parentPath: '/transfer'
      },
      component: () => import('../views/transfer/merchandise.vue'),
    }, {
      path: '/record',
      name: 'record',
      meta: {
        navShow: true,
        title: '转链记录',
        url: '/record',
        parentMPName: '商品转链',
        parentPath: '/transfer'
      },
      component: () => import('../views/transfer/record.vue'),
    }, {
      path: '/taotransfer',
      name: 'taotransfer',
      meta: {
        navShow: true,
        title: '淘宝转链',
        url: '/taotransfer',
        parentMPName: '商品转链',
        parentPath: '/transfer'
      },
      component: () => import('../views/transfer/taotransfer.vue'),
    }, {
      path: '/jingtransfer',
      name: 'jingtransfer',
      meta: {
        navShow: true,
        title: '京东转链',
        url: '/jingtransfer',
        parentMPName: '商品转链',
        parentPath: '/transfer'
      },
      component: () => import('../views/transfer/jingtransfer.vue'),
    },{
      path: '/team',
      name: 'team',
      meta: {
        navShow: true,
        title: '团队',
        parentMPName: '系统管理',
        parentPath: '/tokenmanage'
      },
      component: () => import('@/views/systemmanagement/team.vue')
    }, {
      path: '/kuaishou',
      name: 'kuaishou',
      meta: {
        navShow: true,
        title: '快手',
        parentMPName: '账户管理',
        parentPath: '/kuaishou'
      },
      component: () => import('@/views/accountmanagement/kuaishou.vue')
    }, {
      path: '/douyin',
      name: 'douyin',
      meta: {
        navShow: true,
        title: '抖音',
        parentMPName: '账户管理',
        parentPath: '/kuaishou'
      },
      component: () => import('@/views/accountmanagement/douyin.vue')
    }, {
      path: '/setPageLoading',
      name: 'setPageLoading',
      meta: {
        navShow: true,
        title: '新建落地页',
        parentMPName: '落地页管理',
        parentPath: '/setPageLoading'
      },
      component: () => import('@/views/pageLoading/setPageLoading.vue')
    }, {
      path: '/pageLoadingList',
      name: 'pageLoadingList',
      meta: {
        navShow: true,
        title: '落地页列表',
        parentMPName: '落地页管理',
        parentPath: '/pageLoadingList'
      },
      component: () => import('@/views/pageLoading/pageLoadingList.vue')
    }, {
      path: '/tokenmanage',
      name: 'tokenmanage',
      meta: {
        navShow: true,
        title: 'Token管理',
        parentMPName: '系统管理',
        parentPath: '/tokenmanage'
      },
      component: () => import('@/views/systemmanagement/tokenmanage.vue')
    }, {
      path: '/order',
      name: 'order',
      meta: {
        navShow: true,
        title: '全平台订单',
        parentMPName: '订单管理',
        parentPath: '/order'
      },
      component: () => import('../views/order/order.vue')
    }, {
      path: '/orderpdd',
      name: 'orderpdd',
      meta: {
        navShow: true,
        title: '拼多多订单',
        parentMPName: '订单管理',
        parentPath: '/order'
      },
      component: () => import('../views/order/pddorder.vue')
    }, {
      path: '/ordertb',
      name: 'ordertb',
      meta: {
        navShow: true,
        title: '淘宝订单',
        parentMPName: '订单管理',
        parentPath: '/order'
      },
      component: () => import('../views/order/taoorder.vue')
    }, {
      path: '/orderjd',
      name: 'orderjd',
      meta: {
        navShow: true,
        title: '京东订单',
        parentMPName: '订单管理',
        parentPath: '/order'
      },
      component: () => import('../views/order/jdorder.vue')
    }, {
      path: '/roleaction',
      name: 'roleaction',
      meta: {
        navShow: true,
        title: '权限角色',
        parentMPName: '权限管理',
        parentPath: '/roleaction'
      },
      component: () => import('@/views/systemmanagement/systemprivileges/roleaction.vue')
    }, {
      path: '/routeraddress',
      name: 'routeraddress',
      meta: {
        navShow: true,
        title: '路由地址',
        parentMPName: '权限管理',
        parentPath: '/roleaction'
      },
      component: () => import('@/views/systemmanagement/systemprivileges/routeraddress.vue')
    }, {
      path: '/groupname',
      name: 'groupname',
      meta: {
        navShow: true,
        title: '栏目组名称',
        parentMPName: '权限管理',
        parentPath: '/roleaction'
      },
      component: () => import('@/views/systemmanagement/systemprivileges/groupname.vue')
    }, {
      path: '/details',
      name: 'details',
      meta: {
        navShow: true,
        title: '快手广告计划'
      },
      component: () => import('../views/project/details.vue')
    }, {
      path: '/dydetails',
      name: 'dydetails',
      meta: {
        navShow: true,
        title: '抖音广告计划'
      },
      component: () => import('../views/report/dydetails.vue')
    }]
  },
]

// push
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
 
 
 
// replace
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace= function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const Token = getToken('vue_admin_template_token')
  const userInfo = JSON.parse(sessionStorage.getItem("userINFO"))
  if (Token && userInfo != null) {
    //登陆
    next()
  } else {
    //没有登陆
    if (to.path === '/login') {
      next()
    } else {
     next('/login')
    }
  }

})
export default router
