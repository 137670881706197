const state = {
    isCollapse: false,
    currentMenu: null,
    tabsList: [
        {
            path: '/',
            label: '数据概览',
            name: 'overview'
        }
    ]
}
const mutations = {
    selectMenu(state, val) {
        if (val.name !== 'overview') {
            state.currentMenu = val;
            let result = state.tabsList.findIndex(item => item.name === val.name);
            result === -1 ? state.tabsList.push(val) : ''
        } else {
            state.currentMenu = null
        }
    },
    collapseMenu(state) {
        state.isCollapse = !state.isCollapse
    },
    closeTab(state, val) {
        let result = state.tabsList.findIndex(item => item.name === val.name);
        state.tabsList.splice(result, 1);
    }
}
const actions = {


}
const getters = {

}
export default {
    namespaced: true,
    actions,
    mutations,
    state,
    getters
}